<template>
    <div class="error_page">
      <img class="error_img" src="@/assets/images/error404.png" alt="">
      <h1>page not found(页面未找到)</h1>
      <p>请检查您输入的url是否正确，或单击下面的按钮.</p>
      <router-link to="/">
        <van-button type="info" square  class="btn">返回首页</van-button>
      </router-link>
      
    </div>
</template>

<script>

export default {
  name: '',
  components: {},
  data() {
    return {
        
    };
  },

  methods: {},
}
</script>
<style lang='scss' scoped>
.error_page{
  padding: 30px;
  text-align: center;
  .error_img{
    width: 600px;
    margin: 50px auto 100px;
  }
  h1{
    font-size: 40px;
  }
  p{
    font-size: 24px;
    color: #999;
    line-height: 100px;
    margin-bottom: 40px;
  }
  .btn{
    border-radius: 60px;
    margin:0 auto ;
    display: block;
  }
}

</style>